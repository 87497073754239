import {HttpMethods} from 'tagwalk-api-client/assets/js/common/enums';
import {toggleRegisterPromptModal} from '../common/auth/modalHandler';
import {fetchApi, generateRoute, showError, showSuccess} from '../common/helpers';
import {initCollectionDesignerChoiceModal} from "./collection/components/designerChoiceModal";
import {Router} from "../common/router";
import {translate} from "../common/helpers";

const menu = document.querySelector('#account-menu');
const lang = document.documentElement.lang;

const spinner = document.createElement('span');
spinner.classList.add('spinner');

const queryParams = new URLSearchParams(window.location.search);
const loggedParam = queryParams.get('logged');

/**
 * Check user login status and get flash messages from controller and display them in alerts
 *
 * @access public
 */
export async function checkLogin() {
    if (loggedParam === 'false') {
        await handleLogoutRedirect();
        disableUser();

        return;
    }

    menu.append(spinner);

    const uri = Router.generate('login_status.' + lang);
    const response = await fetch(uri);
    const data = await response.json();

    if (data['flash']) {
        const flashMessages = Object.entries(data['flash']);

        for (const [type, messages] of flashMessages) {
            for (const message of messages) {
                addFlash(type, message);
            }
        }
    }

    if (!response.ok) {
        disableUser();

        if (data['auth_required']) {
            window.location = Router.generate('security_request_authentication.' + lang);
        }

        return;
    }

    if (!data['logged']) {
        localStorage.removeItem('user');
    }

    if (data['logged']) {
        delete data.flash;
        localStorage.setItem('user', JSON.stringify(data));
    }

    if (data['logged']) {
        enableUser(data);
        enableAuthIcon(data);
    } else {
        disableUser();
    }

    if (data['newsletter'] === true) {
        $('.newsletter-subscribe').hide();
    }

    if (data['roles']?.includes('ROLE_BRAND_MANAGER') && data['designers'] !== undefined && data['designers']?.length !== 0) {
        initCollectionDesignerChoiceModal(data);
    } else {
        $('.manage-collections').hide();
    }
}

/**
 * @param {Object} data
 * @param {boolean} data.newsletter
 * @param {boolean} data.survey
 * @param {string} data.email
 * @param {string} data.job_title
 * @param {string} data.country
 */
const enableUser = (data) => {
    $('.account-menu--logged-out').hide();
    $('.account-menu--logged-in').show();

    if (data['account_complete'] === false
        && data['time_difference_created_at_now'] >= 24
        || data['job_needs_update'] === true
    ) {
        toggleRegisterPromptModal();
    }

    menu.removeChild(spinner);

    const event = new CustomEvent('login:true', {
        detail: {
            newsletter: data?.newsletter,
            email: data?.email,
            job_title: data?.job_title
        }
    });

    document.dispatchEvent(event);
    localStorage.setItem('auth', 'logged');

    addManageMembershipLink();
    addDashboardDataLink();
};

const enableAuthIcon = (user) => {
    const accountMenuModeLogged = document.querySelector('.account-menu--logged-in');
    const dropDown = accountMenuModeLogged.querySelector('.tds-dropdown');

    if (dropDown) {
        const accountIdentifierBlock = dropDown.querySelector('#identifierBlock');

        if (!accountIdentifierBlock) {
            return;
        }

        const accountIdentifier = document.createElement('p');
        const userIdentifier = user.email !== "" ? user.slug : "";

        accountIdentifier.textContent = translate('auth.status.logged.title') + userIdentifier;

        if (user.email === "") {
            const iconElement = document.createElement('i');
            iconElement.classList.add('iconoir-facebook');
            accountIdentifier.appendChild(iconElement);
        }

        accountIdentifierBlock.appendChild(accountIdentifier);
    }
}

const disableUser = () => {
    $('.account-menu--logged-in').hide();
    $('.account-menu--logged-out').show();
    menu.removeChild(spinner);
    localStorage.setItem('auth', 'anonymous');
    toggleRegisterPromptModal();
};

const addFlash = (type, message) => {
    if (type === 'danger' || type === 'error') {
        showError(message);

        return;
    }

    showSuccess(message);
};

const addManageMembershipLink = async () => {
    const response = await fetchApi('api_user_paywall_subscription_me', HttpMethods.get);

    if (!response.ok || response.status === 204) {
        return;
    }

    const subscription = await response.json();

    if (!subscription.plan || subscription.status === 'canceled') {
        return;
    }

    const a = document.createElement('a');
    a.setAttribute('href', 'https://billing.stripe.com/p/login/00gfZ46nR8jS0Qo8ww');
    a.innerText = Translator.trans('membership.manage');

    menu.querySelector('.tds-dropdown a:last-child').insertAdjacentElement('beforebegin', a);
}

export const handleLogoutRedirect = async () => {
    const main = document.querySelector('main');
    const route = main?.getAttribute('data-route');

    const response = await fetchApi(
        'security_request_authentication',
        HttpMethods.get,
        {
            'route': route,
            'params': {'show_auth': true, 'sign_in': true}
        }
    );

    if (response) {
        window.location.href = response.url;
    }
}

const addDashboardDataLink = async() => {
    const response = await fetchApi('api_dashboard_subscription_me', HttpMethods.get);

    if (!response || response.status === 204) {
        return;
    }

    const subscription = await response.json();

    if (!subscription.package || subscription.status === 'canceled') {
        return;
    }

    const a = document.createElement('a');
    a.setAttribute('href', generateRoute('data_dashboard_show', {}, false));
    a.innerText = Translator.trans('data_product.dashboard.title');
    menu.querySelector('.tds-dropdown a:last-child').insertAdjacentElement('beforebegin', a);
}
