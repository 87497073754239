var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { render } from 'react-dom';
import { AuthModal } from './modal';
import { fetchApi, getFromLocalStorage, saveInLocalStorage } from "../helpers";
import { HttpMethods } from "tagwalk-api-client/assets/js/common/enums";
var ALLOWED_PATHS = [
    '/',
    '/auth.*',
    '/account/activation.*',
    '/page/legal',
    '/page/cookies',
    '/page/conditions',
    '/press',
    '/about-us',
    '/data-products/dashboard',
    '/newsletter/subscribe',
    '/users/data-requests/[a-zA-Z0-9]+',
    '/premium-reports',
    '/flash-reports',
    '/newsletter/?[a-z-].*',
    '/download/.+/pdf',
    '/page/data-privacy-policy',
    '/page/cookie-policy',
    '/page/tagwalk-mobile-app-privacy-policy',
    '/page/tagwalk-mobile-app-cookie-policy',
    '/brand-visibility',
    '/designer(/.+)*',
    '/collection/.+/.+/.+',
    '/collection-review/.+',
    '/talk/.+',
    '/tagbook',
    '/user/collections/.+',
    '/user/collection/looks/.+',
    '/moodboards/public/.+'
];
var ALLOWED_USER_AGENTS = [
    'Googlebot',
    'Googlebot-Image',
    'Bingbot',
    'Slurp',
    'DuckDuckBot',
    'Baiduspider',
    'YandexBot',
    'YandexMobileBot',
    'Sogou',
    'AOL',
    'Baidu',
    'bot',
    'crawler',
    'spider',
    'Chrome-Lighthouse'
];
var allowAccessToPublicDynamicPages = function () { return __awaiter(void 0, void 0, void 0, function () {
    var localStorageKey, storedPaths, paths, response, pages, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                localStorageKey = 'public_dynamic_pages';
                storedPaths = getFromLocalStorage(localStorageKey);
                paths = storedPaths ? JSON.parse(storedPaths) : null;
                if (!(paths === null)) return [3, 6];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 6]);
                return [4, fetchApi('api_dynamic_page_list', HttpMethods.get, { public: true })];
            case 2:
                response = _b.sent();
                if (!response.ok) return [3, 4];
                return [4, response.json()];
            case 3:
                pages = _b.sent();
                paths = (_a = pages.map(function (page) { return page.path; })) !== null && _a !== void 0 ? _a : [];
                saveInLocalStorage(localStorageKey, JSON.stringify(paths), 600000);
                _b.label = 4;
            case 4: return [3, 6];
            case 5:
                error_1 = _b.sent();
                paths = [];
                return [3, 6];
            case 6:
                paths.forEach(function (path) {
                    if (ALLOWED_PATHS.includes(path) === false) {
                        ALLOWED_PATHS.push(path);
                    }
                });
                return [2];
        }
    });
}); };
var toggleRegisterPromptModal = function () {
    allowAccessToPublicDynamicPages().then(enablePrompt);
};
var initAuth = function (registrationSource) {
    if (registrationSource === void 0) { registrationSource = undefined; }
    var root = document.querySelector('#registerPromptModal');
    if (!root) {
        return;
    }
    disableScroll();
    disableRightClick();
    render(React.createElement(AuthModal, { registrationSource: registrationSource }), root);
};
var enablePrompt = function () {
    if (isUriAllowed() || isUserAgentAllowed()) {
        return;
    }
    initAuth();
};
var disableScroll = function () {
    document.addEventListener('wheel', disableEvent, { passive: false });
    document.addEventListener('touchmove', disableEvent, { passive: false });
    document.addEventListener('keydown', function (e) {
        var keys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Space', 'PageUp', 'PageDown', 'Home', 'End'];
        if (keys.includes(e.code) === true) {
            e.preventDefault();
            return false;
        }
        return true;
    });
};
var disableRightClick = function () {
    document.addEventListener('contextmenu', disableEvent, { passive: false });
};
var disableEvent = function (e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
};
var isUriAllowed = function () {
    var queryParams = new URLSearchParams(window.location.search);
    var showAuth = queryParams.get('show_auth');
    if (showAuth === 'true') {
        return false;
    }
    var uri = window.location.pathname;
    var pathRegex = new RegExp("^/[a-z]{2}(" + ALLOWED_PATHS.join('|') + ")*$");
    if (uri.match(pathRegex) !== null) {
        return true;
    }
    var isCollectionSearch = uri.includes('collection/search');
    var searchParams = new URLSearchParams(window.location.search);
    return isCollectionSearch && (searchParams.has('designer')
        && !searchParams.has('tags[]')
        || searchParams.toString() === '');
};
var isUserAgentAllowed = function () {
    var userAgent = window.navigator.userAgent;
    var userAgentRegex = new RegExp("(" + ALLOWED_USER_AGENTS.join('|') + ")", 'i');
    return userAgent.match(userAgentRegex) !== null;
};
export { toggleRegisterPromptModal, initAuth };
